import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { formatCurrency } from '../../../../helpers/currency.format.helper'
import { getInfoSettlements } from '../../../../redux/api/settlements'
import SimpleLoading from '../../../common/SimpleLoading'
import { Text } from '../../../common/Text'

const InfoSettlements = (props) => {

    const { id } = props

    const [info, setInfo] = useState(null)
    const [adherent, setAdherent] = useState(0)
    const [required, setRequired] = useState(0)
    const [iva, setIva] = useState(0)

    useEffect(() => {
        if (!info) {
            getInformation(id)
        }
        else{
            calculateIva()
        }
    }, [info])

    const getInformation = async id => {
        const request = await getInfoSettlements(id)
        if (request.error) {
            alert("Error")
        }
        else{
            setInfo(request)
        }
    }
    
    if (!info) {
        return <SimpleLoading w="250px"/>
    }

    const calculateIva = () => {

        const adherent = info.orden_ids
        .filter(item => item.importe_iva > 0)
        .reduce((acc, current) => acc + current.importe_a_abonar_sin_iva, 0)
        setAdherent(formatCurrency(adherent.toFixed(2)))

        const required = info.orden_ids
        .filter(item => item.importe_iva <= 0)
        .reduce((acc, current) => acc + current.importe_a_abonar_sin_iva, 0)
        setRequired(formatCurrency(required.toFixed(2)))

        const iva = info.orden_ids
        .filter(item => item.importe_iva > 0)
        .reduce((acc, current) => acc + current.importe_iva, 0)
        setIva(formatCurrency(iva.toFixed(2)))
    }

    return (
        <Wrapper>
            <Title fontWeight="600" fontSize="18px">{info.name}</Title>
            <hr/>
            <Header>
                <Text fontWeight="600">Fecha de presentación</Text>
                <Text fontWeight="600">Período</Text>
                <Text fontWeight="600">Total a obligatorios</Text>
                <Text fontWeight="600">Total a adherentes</Text>
                <Text fontWeight="600">IVA</Text>
                <Text fontWeight="600">Total a liquidar</Text>

                <Text color="#777777">{info.fecha_presentacion}</Text>
                <Text color="#777777">{info.mes} {info.año}</Text>
                <Text color="#777777">${required}</Text>
                <Text color="#777777">${adherent}</Text>
                <Text color="#777777">${iva}</Text>
                <Text color="#777777">${formatCurrency(parseFloat(info.total_liquidacion).toFixed(2))}</Text>
            </Header>
            <Title fontWeight="600" fontSize="18px">Ordenes</Title>
            <Body id="overflow-y">
                <Text fontWeight="600">N°</Text>
                <Text fontWeight="600">Afiliado</Text>
                <Text fontWeight="600">Tipo y N° Doc Afiliado</Text>
                <Text fontWeight="600">Tipo</Text>
                <Text fontWeight="600">Fecha de consumo</Text>
                <Text fontWeight="600">Importe sin IVA</Text>
                <Text fontWeight="600">IVA</Text>
                <Text fontWeight="600">Importe a liquidar</Text>
                {info.orden_ids.map(item => {
                    return <>
                        <Text fontWeight="600">{item.id}</Text>
                        <Text fontWeight="600">{item?.afiliado}</Text>
                        <Text fontWeight="600">{item?.tipo_documento} {item?.numero_documento}</Text>
                        <Text fontWeight="600">{item?.importe_iva > 0 ? 'Adherente' : 'Obligatorio'}</Text>
                        <Text fontWeight="600">{item?.fecha_consumo}</Text>
                        <Text fontWeight="600">${formatCurrency(item?.importe_a_abonar_sin_iva)}</Text>
                        <Text fontWeight="600">${formatCurrency(item?.importe_iva)}</Text>
                        <Text fontWeight="600">${formatCurrency(item.importe_a_abonar.toFixed(2))}</Text>
                    </>
                })}
            </Body>
        </Wrapper>
    )
}

export default InfoSettlements

const Wrapper = styled.div`
    min-width: 250px;
    #overflow-y{
        height: 200px;
        max-height: 200px;
        overflow-y: scroll;
    }
`

const Header = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
`

const Body = styled.div`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;

`

const Title = styled(Text)`
    margin: 15px 0;
`