import React, { useEffect, useState } from "react";
import CardGeneric from "../../components/common/CardGeneric";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import styled from "styled-components";
import InfoDebt from "../../components/ui/organisms/current-account/InfoDebt";
import { useSelector } from "react-redux";
import TableGeneric from "../../components/common/TableGeneric";
import { useSearch } from "../../hooks/useSearch";
import SearchTable from "../../components/common/SearchTable";
import { getCurrentAccount } from "../../redux/api/account";
import { Text } from "../../components/common/Text";
import PageLoading from "../../components/common/PageLoading";
import { formatCurrency } from "../../helpers/currency.format.helper";

const CurrentDebt = () => {

    const { user } = useSelector(state => state.auth)

    // HOOKS
    const [rows, handleRows ] = useSearch()
    const [rowsRequest, setRows] = useState(null)
    const [data, setData] = useState(null)

    useEffect(() => {
        getAccount(user.afiliado_id)
    }, [])

    const getAccount = async (id) => {
        const request = await getCurrentAccount(id)
        if (request.error) {
            return
        }
        setData(request)
        setRows(JSON.parse(request?.cuotas || []))
    }

    const renderTable = () => {
        if (rowsRequest) {
            let result = []
            rowsRequest.map((row) => {
                return result.push(
                    {
                        ...row,
                        importe: row.importe >= 0 ? <Text color="#944030" fontWeight="bold">$ {formatCurrency(row?.importe.toFixed(2) * -1)}</Text> : <Text color="#71a84e" fontWeight="bold">${formatCurrency(row?.importe.toFixed(2) * -1)}</Text>,
                        dateI: new Date(row.create_date).toLocaleDateString(),
                        tipo: row.importe >= 0 ? <Text color="#944030" fontWeight="700">DÉBITO</Text> : <Text color="#71a84e" fontWeight="700">CRÉDITO</Text>,
                        nro: "No se encuentra",
                        cod: "No se encuentra",
                        item: "No se encuentra",
                        cuota: "No se encuentra",
                    }
                )

            })
            return result
        }
        else {
            return []
        }
    }

    // TABLE
    const columns = [
        { id: 'dateI', label: 'Fecha' },
        { id: 'tipo', label: 'T. Movimiento' },
        { id: 'nro', label: 'N° Movimiento' },
        { id: 'cod', label: 'COD. Consumo' },
        { id: 'item', label: 'Item' },
        { id: 'cuota', label: 'Cuota' },
        { id: 'importe', label: 'Importe' },
    ]

    if (!data || !rowsRequest ) {
        return <PageLoading h="100px"/>
    }

    return <CurrentWrapper>
        <CardGeneric title={"Deuda actual"} icon={<AccountBalanceWalletIcon/>}>
            <InfoDebt info={data} user={user}/>
            <SearchTable itemFilter={"name"} rows={renderTable()} handleRows={handleRows}/>
            <TableGeneric columns={rows ? rows : columns} rows={renderTable()} pageSize={10} />
        </CardGeneric>
    </CurrentWrapper>
}

export default CurrentDebt;

const CurrentWrapper = styled.div`
`