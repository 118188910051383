import axios from "axios";

export function getDataUser(payload) {
	return axios.get(`${process.env.REACT_APP_DANSUAPI}/private/afiliado/${payload}`);
}

export async function updateAffiliate(id, body) {
	try {
		const req = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/afiliado/${id}`, body);
		return req.data
	} catch (error) {
		return {error}
	}
}

export async function searchAffiliate(search) {
	try {
		const req = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/prestador_afiliado/search?busqueda=${search}`);
		return req.data
	} catch (error) {
		return {error}
	}
}