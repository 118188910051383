import React from "react";
import styled from "styled-components";
import CheckIcon from '@material-ui/icons/Check';

const SuccesMessage = props => {
    return <SuccesMessageWrapper>
        <Container>
            <Check><CheckIcon/></Check>
            <Title>Solicitud enviada</Title>
        </Container>
    </SuccesMessageWrapper>
}

export default SuccesMessage

const SuccesMessageWrapper = styled.div`
    min-width: 350px;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
`

const Check = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -70px;
    left: calc(50% - 50px);
    svg{
        color: white;
        transform: scale(3);
    }
`

const Title = styled.div`
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    top: -30px;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
`