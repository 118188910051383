import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";
import React from 'react';
import styled from 'styled-components';


const SearchTable = props => {
    const { itemFilter = "id", rows = [], handleRows, title = "Filtrar por item" } = props

    return <SearchTableWrapp>
        <TextFieldFake
            label={title}
            disabled
            InputProps={{
                endAdornment: (
                <InputAdornment>
                    <IconButton>
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
                )
            }}
        />
    </SearchTableWrapp>
}

export default SearchTable;

const SearchTableWrapp = styled.div`
    width: 100%;
    margin: 1rem 0;
`

const TextFieldFake = styled(TextField)`
    width: 30%;
    @media (max-width: 800px) {
        width: 100%;
    }
`