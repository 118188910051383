import React from "react";
import styled from "styled-components";
import CardGeneric from "../../components/common/CardGeneric";
import FormAudit from "../../components/templates/audit/FormAudit";


const Audit = () => {

    return <AuditWrapper>
        <CardGeneric title="Solicitar auditoria Médica">
            <FormAudit/>
        </CardGeneric>
    </AuditWrapper>
}

export default Audit;

const AuditWrapper = styled.div`
    width: 100%;
`