import React, { useEffect, useState } from 'react'
import CardGeneric from '../../components/common/CardGeneric'
import EventNoteIcon from '@material-ui/icons/EventNote';
import { createLot, getOrdersService } from '../../redux/api/settlements';
import PageLoading from '../../components/common/PageLoading';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { Span, Text } from '../../components/common/Text';
import TableGeneric from '../../components/common/TableGeneric';
import styled from 'styled-components';
import { ButtonBlue, ButtonRed } from '../../components/common/ButtonsGenerics';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useSnackBar } from '../../hooks/useSnackBar';
import { SnackBarGeneric } from '../../components/common/SnackBarGeneric';
import { format } from '../../helpers/FormatDate.helper';
import DialogGeneric from '../../components/common/DialogGeneric';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import InfoOrder from '../../components/ui/organisms/orders/InfoOrder';
import InputFile from '../../components/common/InputFile';
import { formatCurrency } from '../../helpers/currency.format.helper';
import SearchGeneric from '../../components/common/SearchGeneric';
import Download from './Dowload';

const NewLot = () => {


    const columns = [
        { id: 'id', label: '#' },
        { id: 'fecha', label: 'Fecha' },
        { id: 'afiliado', label: 'Afiliado' },
        { id: 'tipo_documento', label: 'Tipo DOC.' },
        { id: 'numero_documento', label: 'Documento' },
        { id: 'importe_total', label: 'Importe total' },
        { id: 'actions', label: 'Acciones' }
    ]

    const [orders, setOrders] = useState(null)
    const [initialOrders, setInitialOrders] = useState(null)
    const [order, setOrder] = useState(null)
    const [ordersSelected, setOrdersSelected] = useState([])
    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [closeDialog, setCloseDialog] = useState(false)
    const [filtered, setFiltered] = useState(null)

    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    useEffect(() => {
        !orders && getOrders()
    }, [orders])

    const getOrders = async () => {
        const request = await getOrdersService()
        if (request[0].statusCode === 404) {
            setOrders([])
            setInitialOrders([])
            openSnackBar(
                "error",
                "¡No existen ordenes para el prestador!"
            );
        }
        else {
            setInitialOrders(request)
            setOrders(request)
        }
    }

    const handleOrders = (_, value) => {
        const find = ordersSelected.find(item => item.id === value.id)
        const filter = ordersSelected.filter(item => item.id !== value.id)
        if (!!filtered) {
            setFiltered([...filtered, find])
        }
        else{
            setOrders([...orders, find])
        }
        setOrder(value)
        setOrdersSelected(filter)
        
    }

    const handleForceClose = () => {
        setCloseDialog(!closeDialog)
    }

    const tableRender = array => {
        let result = []
        const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
        if (array) {
            result = array.map(order => {
                return {
                    ...order,
                    fecha: format(order.fecha),
                    fechaFilter: order.fecha,
                    mes: months[new Date(order.fecha+'T00:00:00').getMonth()],
                    actions: <Actions>
                        <DialogGeneric handleForceClose={handleForceClose} close={closeDialog} button={<ButtonBlue><FormatListBulletedIcon /></ButtonBlue>} component={<InfoOrder handleClose={handleForceClose} id={order.id} />} />
                        <ButtonRed disabled={!!filtered} onClick={() => handleDelete(order.id)}><DeleteForeverIcon /></ButtonRed>
                    </Actions>,
                }
            })
        }
        return result
    }

    const handleDelete = (id) => {
        let filter
        let find

        if (!!filtered) {
            filter = filtered.filter(item => item.id !== id)
            find = filtered.find(item => item.id === id)
            setOrdersSelected([...ordersSelected, find])
            setFiltered(filter)
        }
        else{
            filter = orders.filter(item => item.id !== id)
            find = orders.find(item => item.id === id)
            setOrdersSelected([...ordersSelected, find])
            setOrders(filter)
        }
    }

    const handleSubmit = async () => {
        setLoading(true)
        const list = !!filtered ? filtered.map(item => item.id) : orders.map(item => item.id)
        const request = await createLot({
            orden_ids: list,
            // image_name: file.name,
            images: file || []
        })
        setLoading(false)
        if (request.error) {
            openSnackBar(
                "error",
                "¡Error al crear la liquidación!"
            );
        }
        else {
            openSnackBar(
                "success",
                "¡Se creó correctamente!"
            );
            setTimeout(() => {
                setOrdersSelected([])
                setFiltered(null)
                setOrders(null)
                setOrder(null)
            }, 2000);
        }
    }

    const handleB64File = (files) => {
        setFile(files)
    }

    const calculatedTotal = () => {
        let total
        let adherent
        let required
        let iva

        if (!!filtered) {
            total = filtered.reduce((acc, current) => acc + (current.importe_total + current.importe_iva_prestador), 0)

            adherent = filtered
            .filter(item => item.importe_iva_prestador > 0)
            .reduce((acc, current) => acc + current.importe_total, 0)

            required = filtered
            .filter(item => item.importe_iva_prestador <= 0)
            .reduce((acc, current) => acc + current.importe_total, 0)

            iva = filtered
            .filter(item => item.importe_iva_prestador > 0)
            .reduce((acc, current) => acc + current.importe_iva_prestador, 0)
        }
        else{
            total = orders.reduce((acc, current) => acc + (current.importe_total + current.importe_iva_prestador), 0)

            adherent = orders
            .filter(item => item.importe_iva_prestador > 0)
            .reduce((acc, current) => acc + current.importe_total, 0)

            required = orders
            .filter(item => item.importe_iva_prestador <= 0)
            .reduce((acc, current) => acc + current.importe_total, 0)

            iva = orders
            .filter(item => item.importe_iva_prestador > 0)
            .reduce((acc, current) => acc + current.importe_iva_prestador, 0)
        }

        return { total, adherent, required, iva }
    }

    const handleValues = (filtered) => {
        if (filtered.length === orders.length) {
            setOrders(initialOrders)
            setFiltered(null)
        }
        else{
            const result = filtered.map(order => ({...order, actions: <Actions>
                <DialogGeneric handleForceClose={handleForceClose} close={closeDialog} button={<ButtonBlue><FormatListBulletedIcon /></ButtonBlue>} component={<InfoOrder handleClose={handleForceClose} id={order.id} />} />
                <ButtonRed disabled={true} onClick={() => handleDelete(order.id)}><DeleteForeverIcon /></ButtonRed>
            </Actions>,}))
            setFiltered(result)
        }
        setOrdersSelected([])
        calculatedTotal()
    }

    if (!orders) {
        return <PageLoading w="100%" h="100px" />
    }

    return (
        <div>
            <SnackBarGeneric
                show={snackBar.show}
                handleClose={closeSnackBar}
                message={snackBar.message}
                variant={snackBar.variant}
            />
            <CardGeneric title={"Nuevo lote"} icon={<EventNoteIcon />}>
                {ordersSelected.length > 0 && (
                    <Autocomplete
                        options={ordersSelected}
                        getOptionLabel={(option) => `N°${option.id} | ${option.afiliado} | ${option.fecha}`}
                        onChange={handleOrders}
                        renderInput={(params) => <TextField {...params} value={order} label="Seleccione una orden" variant="outlined" />}
                    />)
                }
                {ordersSelected && <>
                    <br />
                    <Text textSize="16px" color="#555555">Ordenes elegidas</Text>
                    <SearchFilter>
                        <SearchGeneric title="Buscar por (fecha de la tabla ó mes escrito)" handleValues={handleValues} filterNames={['fechaFilter', 'fecha', 'mes']} values={tableRender(orders)} />
                        <Download print={filtered || tableRender(orders)}/>
                    </SearchFilter>
                    <TableGeneric columns={columns} rows={filtered || tableRender(orders)} pageSize={10} />
                    <Total>
                        <Text fontSize="18px" fontWeight="bold" color="#555555"><Span color="#888" fontSize="16px">Total obligatorio:</Span> ${formatCurrency(calculatedTotal().required || 0)}</Text>
                        <Text fontSize="18px" fontWeight="bold" color="#555555"><Span color="#888" fontSize="16px">Total adherente:</Span> ${formatCurrency(calculatedTotal().adherent || 0)}</Text>
                        <Text fontSize="18px" fontWeight="bold" color="#555555"><Span color="#888" fontSize="16px">IVA:</Span> ${formatCurrency(calculatedTotal().iva || 0)}</Text>
                        <Text fontSize="18px" fontWeight="bold" color="#555555"><Span color="#888" fontSize="16px">Total:</Span> ${formatCurrency(calculatedTotal().total || 0)}</Text>
                    </Total>
                    <Text textSize="16px" color="#555555">Adjuntar factura</Text>
                    <InputFile handleB64File={handleB64File} />
                </>}
                <Footer>
                    {!loading && <ButtonBlue disabled={orders.length > 0 && !!file ? false : true} onClick={handleSubmit}>Cargar nueva liquidación</ButtonBlue>}
                    {loading && <PageLoading h="auto" />}
                </Footer>
            </CardGeneric>
        </div>
    )
}

export default NewLot

const Actions = styled.div`
    display: flex;
    justify-content: flex-start;
`

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 15px 0;
`

const Total = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    gap: 12px;
    width: 100%;
`

const SearchFilter = styled.div`
    display: flex;
    height: 50px;
    align-items: center;
    gap: 12px;
`