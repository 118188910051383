import { MenuItem, Select, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { searchMedicines } from '../../../redux/api/phamarcy';
import { BadgeUI } from '../../common/Badge';
import { ButtonGreen } from '../../common/ButtonsGenerics';
import PageLoading from '../../common/PageLoading';

const SELECT = [
    { id: 1, name: 'Monodroga' },
    { id: 2, name: 'Nombre Comercial' }
]

const StockForm = props => {
    const { handleResultForm } = props

    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        criterion: null,
        search: null
    })

    const handleForm = e => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleSubmit = async () => {
        setLoading(true)
        const request = await searchMedicines(form)
        if (request.error) {
            alert("Error")
            setLoading(false)
            return
        }
        setLoading(false)
        handleResultForm(sanitizerTable(request))

    }

    const sanitizerTable = array => {
        if (array) {
            let result = []
            array.map((row, i) => {
                return result.push(
                    {
                        ...row,
                        stock: !!row.stock ? (
                            <BadgeUI color="white" bg="#8bc741">Disponible</BadgeUI>
                            ) :
                            (<BadgeUI color="white" bg="#e34d4b">Agotado</BadgeUI>),
                    }
                )
            })
            return result
        }
        else {
            return []
        }
    }

    return <StockFormWrapper>
        <Grid>
            <Select
                fullWidth
                value={form.criterion}
                onChange={handleForm}
                name="criterion"
            >
                {SELECT.map(data => (<MenuItem value={data.id}>{data.name}</MenuItem>))}
            </Select>
            <TextField onChange={handleForm} name="search" value={form.search} type="text" placeholder="Texto de busqueda" />
        </Grid>
        <Footer>
            {!loading ? (
                <ButtonGreen disabled={form.criterion === null || form.search === null} onClick={handleSubmit}>Buscar</ButtonGreen>
            ):
            (   <PageLoading w="100%" h="auto"/>)}
            
        </Footer>
    </StockFormWrapper>
}

export default StockForm;

const StockFormWrapper = styled.div`
    width: 100%;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;  
    }
`

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
`