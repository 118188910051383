import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useSnackBar } from "../../../hooks/useSnackBar";
import { authActions } from "../../../redux/actions";
import { getDataUser, updateProvider } from "../../../redux/api/provider";
import { getCountries, getDepartament, getLocation, getStates } from "../../../redux/api/utils";
import { ButtonBlue, ButtonTransparent } from "../ButtonsGenerics";
import PageLoading from "../PageLoading";
import { SnackBarGeneric } from "../SnackBarGeneric";
import { Text } from "../Text";

const FormUpdateProvider = (props) => {
    const { id, handleClose, headline = false } = props

    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    const [form, setForm] = useState(null)
    const [loading, setLoading] = useState(false)

    // UTILS SELECTS
    const [zip, setzip] = useState(null)
    const [countries, setCountries] = useState(null)
    const [states, setStates] = useState(null)
    const [departaments, setDepartaments] = useState(null)
    const [location, setLocation] = useState(null)

    const { user } = useSelector(state => state.auth)

    // Redux
    const dispatch = useDispatch()

    // EFFECTS
    useEffect(() => {
        getProvider(id)
        getCountriesService()
    }, [])

    useEffect(() => {
        if (form !== null && form.zip) {
            setzip(form.zip)
        }
    }, [form, zip])

    useEffect(() => {
        if (zip !== null && zip.length >= 4) {
            getDataByZip(zip)
            if (form.country) {
                getStatesService(form.country.id)
                if (form.state) {
                    getDepartamentsService(form.state.id)
                    if (form.location) {
                        getLocationService(form.location.id)
                    }
                }
            }
        }
    }, [zip, form?.country?.id])

    // useEffect(() => {

    // }, [zip])

    // FUNCTIONS
    const getProvider = async id => {
        try {
            const { data } = await getDataUser(id)
            setForm({...data, email: user?.email || ''})
        } catch (error) {
            console.log(error);
        }
    }

    const getCountriesService = async () => {
        try {
            const { data } = await getCountries()
            setCountries(data)
        } catch (error) {
            console.log(error);
        }
    }

    const getStatesService = async (id_country) => {
        try {
            const { data } = await getStates(id_country)
            setStates(data)
        } catch (error) {
            console.log(error);
        }
    }

    const getDepartamentsService = async (id_state) => {
        try {
            const { data } = await getDepartament(id_state)
            setDepartaments(data)
        } catch (error) {
            console.log(error);
        }
    }

    const getLocationService = async (id_state) => {
        try {
            const { data } = await getLocation(id_state)
            setLocation(data)
        } catch (error) {
            console.log(error);
        }
    }

    const getDataByZip = async (cp) => {
        try {
            const { data } = await getLocation(cp)
            sanitizerData(data)
        } catch (error) {
            console.log(error);
        }
    }

    const sanitizerData = info => {
        let data = {};
        data.location = info[0]
        data.departamento = info[0]?.departamento
        data.state = info[0]?.departamento?.state
        data.country = info[0]?.departamento?.state?.country
        setForm(state => ({ ...state, ...data }))
    }

    const handleSumbitByCP = (e) => {
        if (e.target.value.length <= 4) {
            setzip(e.target.value)
            setForm(state => ({ ...state, zip: e.target.value }))
        }
    }

    const handleChange = e => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleSelectState = e => {
        setForm({ ...form, departamento: null, [e.target.name]: { id: e.target.value } })
        getDepartamentsService(e.target.value)
    }

    const handleSelectDepartament = e => {
        setForm({ ...form, departamento: null, location: null, [e.target.name]: { id: e.target.value } })
        getLocationService(e.target.value)
    }

    const handleSubmit = async () => {
        const data = {
            phone: form.phone || "",
            mobile: form.mobile || "",
            direccion: form.direccion || "",
            zip: form.zip || "5500",
            country_id: form.country?.id || 0,
            state_id: form.state?.id || 0,
            departamento_id: form.departamento?.id || 0,
            email: form?.email || ''
            // localidad_id: 0
        }
        setLoading(true)
        const request = await updateProvider(id, data)
        if(request.error){
            openSnackBar(
                "error",
                "¡Error en actualizar el prestador!"
            );
            setLoading(false)
        }
        else{
            openSnackBar(
                "success",
                "Se actualizó correctamente"
            );
            setLoading(false)
            // Si es el titular actualizo los datos del titular en redux
            if (headline) {
                setDataUserSession(form)
            }
            setTimeout(() => {
                handleClose()
            }, 1500);
        }
    }

    const setDataUserSession = (form) => {
        const newForm = {
            phone: form.phone || "",
            mobile: form.mobile || "",
            direccion: form.direccion || "",
            zip: form.zip || "5500",
            country: form.country?.name || 0,
            state: form.state?.name || 0,
            departamento: form.departamento?.name || 0,
            email: form?.email
        }
        dispatch(authActions.changeDataUser(newForm))
    }

    if (form === null) {
        return <PageLoading h="200px" w="300px" />
    }

    return <FormUpdateProviderWrapper>
        <Header>
            <Text color="#555555" fontWeight="700" fontSize="1.5rem">Editar datos de contacto</Text>
        </Header>
        <Body>
            <Grid>
                <TextFieldFake type="text" onChange={handleChange} name="mobile" value={form?.mobile} label="Teléfono celular" />
                <TextFieldFake onChange={handleChange} name="phone" value={form?.phone} label="Teléfono fijo" />
            </Grid>
            <TextFieldFake onChange={handleChange} name="direccion" value={form?.direccion} label="Dirección" />
            <TextFieldFake onChange={handleChange} name="email" value={form?.email} label="Correo electrónico" />
            <TextFieldFake
                value={form?.zip}
                onChange={handleSumbitByCP} label="Código Postal" />

            {(
                <>
                    <Grid>
                        {/* PAISES */}
                        {countries && (
                            <FormControl>
                                <InputLabel>Pais</InputLabel>
                                <Select disabled name="country" value={form.country ? form.country.id : 0} label="Pais">
                                    {countries.map(country => <MenuItem value={country.id}>{country.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        )}
                        {states && (
                            <FormControl>
                                <InputLabel>Provincia</InputLabel>
                                <Select onChange={handleSelectState} name="state" value={form.state ? form.state.id : ""} label="Provincia">
                                    {states.map(state => <MenuItem value={state.id}>{state.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid>
                        {departaments && <FormControl>
                            <InputLabel>Departamento</InputLabel>
                            <Select onChange={handleSelectDepartament} name="departamento" value={form.departamento ? form.departamento.id : ""} placeholder="Departamento">
                                {departaments.map(dpto => <MenuItem value={dpto.id}>{dpto.name}</MenuItem>)}
                            </Select>
                        </FormControl>}
                        <FormControl fullWidth>
                        </FormControl>
                    </Grid>
                </>
            )}

            <br />

        </Body>
        {!loading ? <Footer>
            <div>
                <ButtonTransparent onClick={handleClose}>Cancelar</ButtonTransparent>
                <ButtonBlue onClick={handleSubmit}>Guardar</ButtonBlue>
            </div>
        </Footer> : (
            <PageLoading w="100%" h="100px"/>
        )}
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </FormUpdateProviderWrapper>
}

export default FormUpdateProvider;

const FormUpdateProviderWrapper = styled.div`
    min-width: 350px;
    width: 500px;
    min-height: 100px;
    @media (max-width: 768px) {
		width: auto;
	}
`

const Header = styled.div`
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #999999;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const Footer = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const Body = styled.div`
    margin: 2rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`

const Grid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 1rem;
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
	}
`

const TextFieldFake = styled(TextField)`
    width: 100%;
    margin: .5rem 0;
    padding: .5rem 0;
`