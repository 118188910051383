import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import PageLoading from "../../../common/PageLoading";
import { BadgeUI } from "../../../common/Badge";
import { ButtonGreen } from "../../../common/ButtonsGenerics";
import DialogGeneric from "../../../common/DialogGeneric";
import ConfirmShift from "./ConfirmShift";
import { useSnackBar } from "../../../../hooks/useSnackBar";
import { SnackBarGeneric } from "../../../common/SnackBarGeneric";


const NewTurn = (props) => {

    const { shifts, form, handleLastPage } = props

    const [show, setShow] = useState(false)
    const [closeDialog, setCloseDialog] = useState(false)

    const [currentDay, setCurrentDay] = useState(null)
    const [schedule, setSchedule] = useState(null)
    const [hour, setHour] = useState(null)
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    useEffect(() => {
        if (shifts) formatedData(shifts); setCurrentDay(null); setHour(null);
    }, [shifts])

    const handleDateClick = (e) => { 
        const { dateStr } = e
        let result = false
        shifts.forEach(element => {
            if (dateStr === element.fecha) {
                result = {...element, turnos: JSON.parse(element.turnos)}
            }
        });
        setHour(null)
        setCurrentDay(result)
    }

    const formatedData = (array) => {
        const newArray = array.map(element => {
            return {
                title: "Turno disponible",
                date: element.fecha,
            }
        });
        setSchedule(newArray)
    }

    const handleHourShift = data => {
        setHour(data)
    }

    const handleForceClose = () => {
        setCloseDialog(!closeDialog)
        setShow(false)
    }

    const handleResult = (status) => {
        setCurrentDay(null)
        if (status) {
            openSnackBar(
                "success",
                "¡Se creo el turno correctamente!"
            );
            setTimeout(() => {
                handleLastPage()
            }, 2000);
        }
        else{
            openSnackBar(
                "error",
                "¡Ocurrió un problema al solicitar el turno!"
            );
        }
    }

    if (!schedule) {
        return <PageLoading w="100%" h="100px"/>
    }

    return <NewTurnWrapper>
        <Calendar>
            <Title>Especialidad seleccionada</Title>
            <Data>{form?.formText?.speciality}</Data>
            <br/>
            <Title>Seleccione una fecha</Title>
            <CalendarWrapper>
                <FullCalendar
                    plugins={[ dayGridPlugin, interactionPlugin ]}
                    // initialView="dayGridMonth"
                    events={schedule}
                    locale={"es"}
                    themeSystem
                    dateClick={handleDateClick}
                />
            </CalendarWrapper>
        </Calendar>
        
        {currentDay && (
            <Calendar>
                <Title>Dia del turno</Title>
                <Data>{currentDay.fecha}</Data>
                {/* {console.log(currentDay)} */}
                <br/>
                <Title>Seleccione un horario disponible</Title>
                <GridShift>
                    {currentDay.turnos.map(shift => {
                        if(shift.estado === "disponible"){
                            return <BadgeUIFake active={shift.id === hour?.id ? true : false} onClick={() => handleHourShift(shift)} color="white" bg="#0b0440">{shift.hora}</BadgeUIFake>
                        }
                        else{
                            return <BadgeUIDisabled color="white" bg="#0b044055">{shift.hora}</BadgeUIDisabled>
                        }
                    })}
                </GridShift>
                {hour && (
                    <DialogGeneric close={closeDialog} handleForceClose={handleForceClose} handleShow={handleForceClose} show={show} button={<ButtonGreen fullWidth>RESERVAR TURNO</ButtonGreen>} component={<ConfirmShift handleClose={handleForceClose} handleResult={handleResult} values={form.formText} currentDay={currentDay} hour={hour} afilliate={form.affiliate} form={{hour: hour.id, affiliate: form.affiliate.afiliado_id}}/>}/>
                )}
            </Calendar>
        )}
        {/* Snack */}
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />

    </NewTurnWrapper>
}

export default NewTurn

const NewTurnWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
    }
`

const Calendar = styled.div`
    padding: 3rem;
    @media (max-width: 800px) {
        padding: .3rem;
    }
`

const Title = styled.div`
    font-weight: 700;
    color: #555555;
    font-size: .9rem;
    text-transform: uppercase;
`

const Data = styled.div`
    color: #0f0f0f;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 700;
`

export const CalendarWrapper = styled.div`
    .fc-daygrid-event{
        background-color: #8bc741;
        border: 1px solid #8bc741;
    }
    .fc-daygrid-day-top{
        cursor: pointer;
        :hover{
            background-color: #8bc74155;
        }
    }
  /* .fc td {
    background: #ff9e9e;
  }
  .fc th {
    background: #f5f5f5;
    border: 1px solid black;
  }
  .fc-daygrid-event-harness{
      background-color: #8cde66;
  }
  .fc-daygrid-day-frame{
      border: .51px solid #f5f5f5;
  }
  .fc-daygrid-day-top{
      background-color: #f5f5f5;
  }
  tbody{
      cursor: pointer;
  } */
`

const GridShift = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: .5rem;
    margin: 1rem 0;
`

const BadgeUIFake = styled(BadgeUI)`
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: all .1s ease-in-out;
    background-color: ${p => p.active ? "#528bab" : null};
    :hover{
        filter: opacity(.5);
    }
`

const BadgeUIDisabled = styled(BadgeUI)`
    display: flex;
    justify-content: center;
`