import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonTransparent } from "../../common/ButtonsGenerics";
import CardGeneric from "../../common/CardGeneric";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InfoIcon from '@material-ui/icons/Info';
import TabsComponent from "../../common/TabsComponent";
import PageLoading from "../../common/PageLoading";
import { getConventionsById } from "../../../redux/api/conventions";
import Benefits from "../../ui/organisms/conventions/Benefits";
import Profesional from "../../ui/organisms/conventions/Profesional";
import RequestModifications from "../../ui/organisms/conventions/RequestModifications";
import Information from "../../ui/organisms/conventions/Information";


const TakeConventions = props => {
    const { id, handleBackPage } = props

    const tabs = [
        { label: `Prestaciones`, id: 0 },
        { label: `Profesionales`, id: 1 },
        { label: 'Solicitud modificaciones', id: 2 },
    ];

    const [tab, setTab] = useState(0);
    const [convention, setConvention] = useState(null);

    useEffect(() => {
      !convention && getConvention()
    }, [convention]);
    

    const handleTab = (e, value) => {
        setTab(value);
    };

    const getConvention = async () => {
        const request = await getConventionsById(id)
        if (request.error) {
            alert("Error")
        }
        else{
            setConvention(request)
        }
    }

    const renderTab = tab => {
        const cases = {
            0: <Benefits benefits={convention?.prestaciones || []}/>,
            1: <Profesional profesional={convention?.profesionales || []}/>,
            2: <RequestModifications requests={convention?.solicitudes || []}/>
        }

        return cases[tab]
    }

    return <TakeConventionsWrapper>
        <ArrowBackContainer>
            <ButtonTransparent onClick={handleBackPage}><ArrowBackIcon/><NameButton>Atras</NameButton></ButtonTransparent>
        </ArrowBackContainer>
        {!!convention ? 
        (<CardGeneric icon={<InfoIcon/>} title="Información del convenio">
            <Information convention={convention}/>
            <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs}/>
            {renderTab(tab)}
        </CardGeneric>)
        :
        (<CardGeneric icon={<InfoIcon/>} title="Información del convenio">
            <PageLoading h="60px"/>
        </CardGeneric>)}
    </TakeConventionsWrapper>
}

export default TakeConventions

const TakeConventionsWrapper = styled.div`
    width: 100%;
`

const ArrowBackContainer = styled.div`
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const NameButton = styled.div`
    margin-left: 1rem;
    font-size: 1rem;
`