import React from "react";
import { createTheme, StylesProvider, ThemeProvider } from "@material-ui/core";

export function MaterialThemeProvider(props) {
	const theme = createTheme({
		typography: {
			fontFamily: ["Lato"].join(","),
			fontSize: 14,
			button: {
				textTransform: "none",
				fontWeight: "bold",
			},
		},
		palette: {
			primary: {
				// light: will be calculated from palette.primary.main,
				main: "#7dbd6a",
				// dark: will be calculated from palette.primary.main,
				// contrastText: "#fff" //will be calculated to contrast with palette.primary.main
			},
			secondary: {
				// light: will be calculated from palette.primary.main,
				main: "#ffffff",
				// dark: will be calculated from palette.primary.main,
				// contrastText: "#fff" //will be calculated to contrast with palette.primary.main
			},
			muted: {
				main: "#c4c4c4",
			},
			error: {
				// light: will be calculated from palette.primary.main,
				main: "#b31d15",
				// dark: will be calculated from palette.primary.main,
				// contrastText: "#fff" //will be calculated to contrast with palette.primary.main
			},
			success: {
				main: "#9bc90e",
			}
		},
		props: {
			// Name of the component ⚛️
			MuiButtonBase: {
				// The properties to apply
				disableRipple: false, // No more ripple, on the whole application 💣!
			},

			// Set default elevation to 1 for popovers.
			MuiPopover: {
				elevation: 1,
			},
		},
	});
	const { children } = props;

	return (
		<StylesProvider injectFirst>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</StylesProvider>
	);
}
