import * as types from "../types/layout";

const initialState = {
	pages: [
		{
			name: "Incio"
		},
		{
			name: "Afiliado"
		},
		{
			name: "Solicitudes"
		},
		{
			name: "Turnos"
		},
		{
			name: "Ordenes"
		},
		{
			name: "Farmacia"
		},
		{
			name: "Cuenta Corriente"
		},
		{
			name: "Contacto"
		},
	],
	pageNow: null,
	modeMenu: false,
	mobileMenu: false,
	searchInformationPages: null
};

export const layout = (state = initialState, action) => {
	switch (action.type) {
		// GET FINANCES
		case types.SET_PAGE_NOW:
			return {
				...state,
				pageNow: action.payload
			};
		case types.SET_MENU_MODE:
			return {
				...state,
				modeMenu: action.payload
			};
		case types.SET_MENU_MOBILE:
			return {
				...state,
				mobileMenu: action.payload
			};
		default:
			return state;
	}
};
