import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TableGeneric from "../../components/common/TableGeneric";
import EventNoteIcon from '@material-ui/icons/EventNote';
import { Button, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import CardGeneric from "../../components/common/CardGeneric";
import TakeTurn from "../../components/templates/shifts/TakeTurn";
import { getShifts } from "../../redux/api/shifts";
import { useSelector } from "react-redux";
import PageLoading from "../../components/common/PageLoading";
import DialogGeneric from "../../components/common/DialogGeneric";
import { ButtonRed } from "../../components/common/ButtonsGenerics";
import { Text } from "../../components/common/Text";
import CancelIcon from '@material-ui/icons/Cancel';
import CancelShift from "../../components/ui/organisms/shifts/CancelShift";
import { SnackBarGeneric } from "../../components/common/SnackBarGeneric";
import { useSnackBar } from "../../hooks/useSnackBar";

const Shifts = () => {

    const [shiftsPage, setShiftsPage] = useState(null)
    const [shifts, setShifts] = useState(null)
    const [affiliate, setAffiliate] = useState(null);
    
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();
    
    const { user, family } = useSelector(state => state.auth)
    const members = [user, ...family]

    
    useEffect(() => {
        if (shifts === null) {
            getAllShifts(user.afiliado_id)
            setAffiliate(null)
        }
    }, [shifts])

    useEffect(() => {
        if (affiliate !== null) {
            getAllShifts(affiliate.afiliado_id)
        }
    }, [affiliate])

    const columns = [
        { id: 'fecha', label: 'Fecha' },
        { id: 'hora', label: 'Hora' },
        { id: 'especialidad_name', label: 'Especialidad' },
        { id: 'profesional_name', label: 'Profesional' },
        { id: 'estado', label: 'Estado' },
        { id: 'acciones', label: 'Acciones' },
    ]

    const handleClick = () => {
        setShiftsPage(1)
    }

    const handleBackPage = () => {
        setShifts(null)
        setShiftsPage(null)
    }

    const handleLastPage = () => {
        handleBackPage()
    }

    const handleChangeAffiliate = (event) => {
        setAffiliate(event.target.value);
    };

    const getAllShifts = async (id) => {
        const request = await getShifts(id)
        if (request.error) {
            alert("Error")
            return
        }
        setShifts(sanitizerShifts(request))
    }

    const handleResult = (status) => {
        if (status) {
            openSnackBar(
                "success",
                "¡Se canceló el turno correctamente!"
            );
            setShifts(null)
        }
        else {
            openSnackBar(
                "error",
                "¡Ocurrió un problema al cancelar el turno!"
            );
        }
        // setAffiliate(null)
    }

    const sanitizerShifts = array => {
        let result = []
        if (array) {
            array.map((row, i) => {
                if (row.estado !== "cancelado") {
                    return result.push(
                        {
                            ...row,
                            estado: <Text color={row.estado === "cancelado" ? "red" : "#555555"}>{row.estado.toUpperCase()}</Text>,
                            acciones: <Actions>
                                <DialogGeneric button={<ButtonRed><CancelIcon /></ButtonRed>} component={<CancelShift handleResult={handleResult} shiftId={row.id} affiliateId={affiliate?.afiliado_id || user.afiliado_id} />} />
                            </Actions>,
                        }
                    )
                }
                else {
                    return result.push(
                        {
                            ...row,
                            estado: <Text color={row.estado === "cancelado" ? "red" : "#555555"}>{row.estado.toUpperCase()}</Text>,
                            acciones: <Actions>
                                <ButtonRed disabled={true} ><CancelIcon /></ButtonRed>
                            </Actions>,
                        }
                    )
                }

            })
            return result
        }
    }


    if (shiftsPage === 1) {
        return <TakeTurn handleLastPage={handleLastPage} handleBackPage={handleBackPage} />
    }

    if (shifts === null) {
        return <PageLoading w="100%" h="100px" />
    }

    return <ShiftsWrapper>
        <CardGeneric title={"Turnos"} icon={<EventNoteIcon />} handleClick={handleClick} button={Button} buttonName={"Reservar Turno"}>
            <Search>
                <FormControlFake>
                    <InputLabel id="1">Seleccione un afiliado</InputLabel>
                    <SelectFake
                        labelId="1"
                        value={affiliate || members[0]}
                        onChange={handleChangeAffiliate}
                    >
                        {members.map(member => <MenuItem value={member}>{`${member.nombre} ${member.apellido}`}</MenuItem>)}
                    </SelectFake>
                </FormControlFake>
            </Search>
            <TableGeneric columns={columns} rows={shifts} pageSize={10} />
        </CardGeneric>
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </ShiftsWrapper>
}

export default Shifts;

const ShiftsWrapper = styled.div`
    width: 100%;
`

const Actions = styled.div`
    display: flex;
    justify-content: flex-start;
`

const SelectFake = styled(Select)`
    width: 100%;
`

const FormControlFake = styled(FormControl)`
    width: 100%;
`

const Search = styled.div`
    width: 100%;
    gap: 1rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 1rem 0;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }
`