import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import PageLoading from '../../../common/PageLoading';
import SearchGeneric from '../../../common/SearchGeneric';
import TableGeneric from '../../../common/TableGeneric';

const Benefits = (props) => {

    const { benefits } = props

    const [rows, setRows] = useState(null)
    const [filtered, setFiltered] = useState(null)

    useEffect(() => {
        !!benefits && renderTable(benefits)
    }, [benefits])

    const renderTable = array => {
        const benefits = array.map(item => ({
            ...item,
            valor_total: `$${item.valor_total.toFixed(2) || 0.00}`,
            honorarios_medicos: `$${item.honorarios_medicos.toFixed(2) || 0.00}`,
            modulos: `$${item.modulos.toFixed(2) || 0.00}`,
            gastos: `$${item.gastos.toFixed(2) || 0.00}`,
            ayudante: `$${item.ayudante.toFixed(2) || 0.00}`, 
        }))
        setRows(benefits)
    }
    

    const columns = [
        { id: 'prestacion', label: 'Nombre' },
        { id: 'honorarios_medicos', label: 'Honorarios Médicos' },
        { id: 'ayudante', label: 'Ayudante' },
        { id: 'gastos', label: 'Gastos' },
        { id: 'modulos', label: 'Modulos' },
        { id: 'valor_total', label: 'Valor Total' },
    ]

    const handleValues = (filtered) => {
        setFiltered(filtered)
    }

    if (!rows) {
        return <PageLoading h="160px"/>
    }

    return <Wrapper>
        <SearchGeneric title="Buscar prestaciones" handleValues={handleValues} filterNames={['prestacion']} values={rows} />
        <TableGeneric columns={columns} rows={filtered || rows} pageSize={10} />
    </Wrapper>;
};

export default Benefits;

const Wrapper = styled.div`
    display: grid;
    gap: 10px;
`
