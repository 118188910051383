import { Button, TextField } from '@material-ui/core';
import React, { useState } from 'react'
import styled from "styled-components";
import { useSnackBar } from '../../../../hooks/useSnackBar';
import { deleteOrderById } from '../../../../redux/api/orders';
import { ButtonBlue } from '../../../common/ButtonsGenerics';
import PageLoading from '../../../common/PageLoading';
import { SnackBarGeneric } from '../../../common/SnackBarGeneric';
import { Text } from '../../../common/Text';

const ConfirmOrder = (props) => {

    const { id, handleClose } = props

    const [message, setmessage] = useState("")
    const [loading, setLoading] = useState(false);
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    const handleChange = e => {
        setmessage(e.target.value)
    }

    const handleSubmit = async () => {
        setLoading(true)
        const request = await deleteOrderById(message, id)
        if (request.error) {
            openSnackBar(
                "error",
                "¡Ocurrió un error al anular orden!"
            );
        }
        else{
            openSnackBar(
                "success",
                "¡Se anuló la orden correctamente!"
            );
            setTimeout(() => {
                handleClose(id)
            }, 1500);
        }
        setLoading(false)
    }

    return (
        <ConfirmOrderWrapper>
            <Header>
                <Text color="#555555" fontSize="2rem" fontWeight="bold">Anular orden</Text>
            </Header>
            <Body>
                <TextField multiline rows={3} value={message} onChange={handleChange} label="Ingresar motivo de anulación" fullWidth/>
            </Body>
            {!loading ?<Footer>
                <Button onClick={handleClose}>Cancelar</Button>
                <ButtonBlue disabled={message === "" ? true : false} onClick={handleSubmit}>Enviar</ButtonBlue>
            </Footer>  : <PageLoading h="50px"/>}
            <SnackBarGeneric
                show={snackBar.show}
                handleClose={closeSnackBar}
                message={snackBar.message}
                variant={snackBar.variant}
            />
        </ConfirmOrderWrapper>
    )
}

export default ConfirmOrder

const ConfirmOrderWrapper = styled.div`
    min-width: 350px;
    width: 100%;
`

const Footer = styled.div`
    padding: 10px;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
`

const Header = styled.div`
    width: 100%;
    padding: 10px 0;
    border-bottom: 2px solid #aaaaaa;
`

const Body = styled.div`
    padding: 25px 0;
`