import { FormControl, Input, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ButtonGreen } from "../../common/ButtonsGenerics";
import SuccesMessage from "../../common/dialog/SuccessMessage";
import DialogGeneric from "../../common/DialogGeneric";
import ErrorPage from "../../common/ErrorPage";
import SimpleLoading from "../../common/SimpleLoading";
import InfoUser from "../../common/user/InfoUser";

const FormAudit = (props) => {

    // Redux
    const { family, user } = useSelector(state => state.auth)
    const { affiliate: affiliateRedux } = useSelector(state => state.affiliate)

    // State form
    const [affiliate, setAffiliate] = useState(affiliateRedux)
    const [phone, setPhone] = useState({value: null, error: false})
    const [email, setEmail] = useState({value: null, error: false})
    const [typeAudit, setTypeAudit] = useState({value: null, error: false})
    const [coment, setComent] = useState({value: null, error: false})
    const [fromAudit, setFromAudit] = useState({value: null, error: false})
    const [file, setFile] = useState(null)
    const [fileError, setFileError] = useState(false)

    const [openDialog, setOpenDialog] = useState(false)
    const [loading, setLoading] = useState(false)

    // Functions
    const handleChangeAffiliate = (e) => {
        setAffiliate(e.target.value)
    }

    const validateForm = () => {
        if (phone.value === null) {
            setPhone({...phone, error: "Completar el teléfono correctamente"}) 
            return false
        }
        if (email.value === null) {
            setEmail({...email, error: "Completar el correo electrónico correctamente"})
            return false
        }
        if (coment.value === null) {
            setComent({...coment, error: "Completar el comentario correctamente"})
            return false
        }
        if (fromAudit.value === null) {
            setFromAudit({...fromAudit, error: "Completar el lugar correctamente"})
            return false
        }
        if (file === null) {
            setFileError(true)
            return false
        }
        return true
    }

    const handleFile = e => {
        setFile(e.target.files[0])
        setFileError(false)
    }

    const handleSubmit = () => {
        setOpenDialog(false)
        const isValidate = validateForm()
        if (isValidate) {
            const formData = {
                affiliate,
                email: email.value,
                comment: coment.value,
                fromAudit: fromAudit.value,
                phone: phone.value,
                file
            }
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
                setOpenDialog(true)
            }, 2000);
        }
    }

    if (affiliate === null) {
        return <ErrorPage message="No se encuentra ningún afiliado"/>
    }

    return <ContainerWrapper>
        <Header>
            <FormControlFake>
                <InputLabel id="1">Seleccione un afiliado</InputLabel>
                <SelectFake
                    labelId="1"
                    value={affiliate}
                    onChange={handleChangeAffiliate}
                >
                    {
                        user.titular === true ?
                            (family.map(member => <MenuItem value={member}>{`${member.nombre} ${member.apellido}`}</MenuItem>)) :
                            (<MenuItem value={user}>{`${user.nombre} ${user.apellido}`}</MenuItem>)
                    }

                </SelectFake>
            </FormControlFake>
        </Header>
        <Left>
            {affiliate !== null && (
                <FormWrapper>
                    <Content>
                        <Title>datos de usuario</Title>
                        <InfoUser affiliate={affiliate} />
                    </Content>
                    <Content>
                        <Title>Datos de contacto</Title>
                        <FormControlFake>
                            <TextField
                                type="number"
                                onChange={e => setPhone({error: false, value: e.target.value})}
                                value={phone.value}
                                error={!!phone.error}
                                label={"Teléfono"}
                                helperText={phone.error}
                            />
                        </FormControlFake>
                        <FormControlFake>
                            <TextField
                                type="email"
                                onChange={e => setEmail({error: false, value: e.target.value})}
                                value={email.value}
                                error={!!email.error}
                                helperText={email.error}
                                label={"Correo electrónico"}
                            />
                        </FormControlFake>
                    </Content>
                    <Content>
                        <Title>Datos de auditoria</Title>
                        <FormControlFake>
                            <InputLabel id="2">Tipo de auditoria</InputLabel>
                            <SelectFake
                                labelId="2"
                                value={typeAudit.value}
                                // onChange={handleChangeAffiliate}
                            >

                            </SelectFake>
                        </FormControlFake>
                        <FormControlFake>
                            <TextField
                                type="text"
                                onChange={e => setFromAudit({error: false, value: e.target.value})}
                                value={fromAudit.value}
                                error={!!fromAudit.error}
                                helperText={fromAudit.error}
                                label={"Lugar de auditoria"}
                            />
                        </FormControlFake>
                    </Content>
                </FormWrapper>
            )}
        </Left>
        <Right>
            <Content>
                <Title>Comentarios</Title>
                <FormControlFake>
                    <TextField
                        type="email"
                        onChange={e => setComent({error: false, value: e.target.value})}
                        value={coment.value}
                        error={!!coment.error}
                        helperText={coment.error}
                        label={"Ingresa tu comentario"}
                        multiline
                        rows={4}
                    />
                </FormControlFake>
                <br />
                <br />
                <br />
                <br />
                <Title>Archivo adjunto</Title>
                <FormControlFake>
                    <LabelFile error={!fileError} htmlFor="file">Agregar archivo</LabelFile>
                    <InputHiden
                        id="file"
                        type="file"
                        onChange={handleFile}
                    />
                </FormControlFake>
            </Content>
        </Right>
        <Footer>
            {!loading ? 
            (<ButtonGreen onClick={handleSubmit}>ENVIAR</ButtonGreen>):
            (<SimpleLoading height="100px" justifyContent="center"/>)
            }
            
        </Footer>
        {!!openDialog && (<DialogGeneric component={<SuccesMessage/>}/>)}
       
    </ContainerWrapper>
}

export default FormAudit

const ContainerWrapper = styled.div`
    width: calc(100% - 2rem);
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "header header"
                         "left   right"
                         "footer footer";
`

const Header = styled.div`
    padding: 1rem;
    width: calc(50% - 2rem);
    grid-area: header;
`

const Left = styled.div`
    padding: 1rem;
    width: calc(100% - 2rem);
    grid-area: left;
`

const Right = styled.div`
    padding: 1rem;
    width: calc(100% - 2rem);
    grid-area: right;
`

const SelectFake = styled(Select)`
    width: 100%;
`

const FormControlFake = styled(FormControl)`
    width: 100%;
`

const Title = styled.div`
    text-transform: uppercase;
    font-weight: 600;
    color: #555555;
`

const FormWrapper = styled.div`
    margin-top: 1rem;
`

const Content = styled.div`
    margin-top: 2rem;
`

const InputHiden = styled.input`
    display: none;
`

const LabelFile = styled.label`
    cursor: pointer;
    width: 100%;
    border: ${p => p.error ? "1px solid #528bab" : "1px solid #b31d15"};
    border-radius: 3px;
    margin-top: 1rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: 700;
    color: ${p => p.error ? "#528bab" : "#b31d15"};
   
`

const Footer = styled.div`
    grid-area: footer;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
`