import axios from "axios";

export function getDataUser(payload) {
	return axios.get(`${process.env.REACT_APP_DANSUAPI}/private/prestador_prestador/info`);
}

export async function updateProvider(id, body) {
	try {
		const req = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/prestador_prestador/info`, body);
		return req.data
	} catch (error) {
		return {error}
	}
}

export async function getValuesProviders(affiliate, plan, provider) {
	try {
		const body = {
			afiliado_id: affiliate,
			plan_id: plan,
			prestacion_id: provider
		}
		const req = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/orden_prestador/get_valores_prestacion`, body);
		return req.data
	} catch (error) {
		return {error}
	}
}

export async function getProviders(body) {
	try {
		const req = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/orden_prestador/prestaciones`, body);
		return req.data
	} catch (error) {
		return {error}
	}
}

export async function changePassword(body) {
	try {
		const req = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/prestador_prestador/change_password`, body);
		return req.data
	} catch (error) {
		return {error}
	}
}

export async function resetPassword(body) {
	try {
		const req = await axios.post(`${process.env.REACT_APP_DANSUAPI}/public/prestador/reset_password`, body);
		return req.data
	} catch (error) {
		return {error}
	}
}

export async function changePasswordToken(token, body) {
	const config = {
		headers: {
			Authorization: 'Bearer ' + token
		}
	}
	try {
		const req = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/prestador_prestador/change_password`, body, config);
		return req.data
	} catch (error) {
		return {error}
	}
}