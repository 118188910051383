import React from 'react';
import styled from 'styled-components';
import { Span, Text } from '../../../common/Text';

const Information = (props) => {

    const { convention } = props

    return <InformationWrapper>
        <Text color="#333333" fontSize="1.5rem" fontWeight="bold">{convention.name}</Text>
        <Grid>
            <Text color="#333333" fontSize="1rem" fontWeight="bold"><Span fontWeight="500">Fecha de celebración: </Span>{convention.fecha_celebracion}</Text>
            <Text color="#333333" fontSize="1rem" fontWeight="bold"><Span fontWeight="500">Fecha fin de vigencia: </Span>{convention.vigencia_hasta}</Text>
            <Text color="#333333" fontSize="1rem" fontWeight="bold"><Span fontWeight="500">Fecha de inicio de vigencia: </Span>{convention.vigencia_inicio}</Text>
            <Text color="#333333" fontSize="1rem" fontWeight="bold"><Span fontWeight="500">Vigencia: </Span>{convention.vigencia}</Text>
            <Text color="#333333" fontSize="1rem" fontWeight="bold"><Span fontWeight="500">Monto total: </Span>{convention.monto_total}</Text>
        </Grid>
    </InformationWrapper>;
};

export default Information;


const InformationWrapper = styled.div`
    margin: 12px 0;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 300px auto;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;  
    }
`