import { Avatar } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const InfoUser = props => {
    const { provider } = props

    return <User>
    <Section>
        <Avatar>{provider.name[0]}</Avatar>
    </Section>
    <Section>
        <Subtitle>NOMBRE</Subtitle>
        <Value>{provider.name}</Value>
    </Section>
    <Section>
        <Subtitle>CUIL/CUIT</Subtitle>
        <Value>{provider.cuil_cuit}</Value>
    </Section>
    <Section>
        <Subtitle>TIPO PROVEEDOR</Subtitle>
        <Value>{provider.tipo_proveedor_id}</Value>
    </Section>
</User>
}

export default InfoUser;

const User = styled.div`
    width: auto;
    height: 50px;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: 768px){
        max-width: 300px;
        overflow-x: auto;
    }
`

const Section = styled.section`
    width: auto;
    padding: 0 1rem;
`

const Subtitle = styled.div`
    font-size: .8rem;
    color: #777777;
    font-weight: 700;
`

const Value = styled.div`
    font-size: .9rem;
`