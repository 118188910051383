import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from "styled-components";
import { toTitleCase } from "../../../../helpers/TitleCase.helper";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DialogPassword from "./Password";

const DataUser = (props) => {

  const { aside } = props

  const { user } = useSelector(state => state.auth)
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const name = toTitleCase(`${user?.user_name || user?.name}`)

  return <AccordionWrapper>
    <AccordionSummary
      expandIcon={aside ? <ExpandMoreIcon color="secondary" /> : null}
    >
      <Details>
        <Photo src={user.image} />
        {aside ? <Name>{name}</Name> : null}
      </Details>
    </AccordionSummary>
    <AccordionDetailsFake>
      <Pass onClick={handleClickOpen} aside={aside}>{aside ? "Cambiar contraseña" : <ExitToAppIcon />}</Pass>
      <Logout aside={aside} to="auth/logout">{aside ? "Cerrar sesión" : <ExitToAppIcon />}</Logout>
    </AccordionDetailsFake>
    <DialogPassword open={open} handleClose={handleClose} />
  </AccordionWrapper>
}

export default DataUser;

const AccordionWrapper = styled(Accordion)`
    background-color: #00000000;
    border: none;
    box-shadow: none;
    border-radius: 0px;
    border-bottom: 2px solid white;
    width: 100%;
    :last-child{
        border-radius: 0px;
    }
`

const AccordionDetailsFake = styled(AccordionDetails)`
    display: flex;
    flex-direction: column;
`

const Details = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

const Photo = styled.img`
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
`
const Name = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    color: white;
    font-weight: 600;
    font-size: .8rem;
    padding-left: 10px;
`

const Logout = styled(Link)`
    width: 100%;
    text-decoration: none;
    color: white;
    border-radius: 3px;
    background-color: #7dbd6a55;
    padding: 0.3rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: .8rem;
    transition: .3s ease-in-out all;
    justify-content: center;
    ${p => !p.aside ? "padding: 0" : null};
    :hover{
      background-color: white;
      color: #7dbd6a;
    }
`

const Pass = styled.div`
    width: 100%;
    text-decoration: none;
    color: white;
    border-radius: 3px;
    background-color: #7dbd6a55;
    padding: 0.3rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: .8rem;
    transition: .3s ease-in-out all;
    justify-content: center;
    cursor: pointer;
    ${p => !p.aside ? "padding: 0" : null};
    :hover{
      background-color: white;
      color: #7dbd6a;
    }
`