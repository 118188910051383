import { useState } from "react"

export const useSearch = () => {
  const [rowsState, setRows] = useState(null)

  const handleRows = (result) => {
      setRows(result)
  }

  return [rowsState, handleRows]
}
