import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import CallIcon from '@material-ui/icons/Call';
import SmartphoneIcon from '@material-ui/icons/Smartphone';


const Profileuser = () => {

    const { user } = useSelector(state => state.auth)

    return <ProfileuserWrapper>
        <LogoContainer>
            <ProfileImg src={user.image}/>
        </LogoContainer>
        <DescriptionUser>
                
                <Grid>
                    <div>
                        <Title>Nombre</Title>
                        <ValueData>{user.name}</ValueData>
                    </div>
                </Grid>
                <Grid>
                    <div>
                        <Title>CUIL/CUIT</Title>
                        <ValueData>{user.cuil_cuit}</ValueData>
                    </div>
                    <div>
                        <Title>Tipo proveedor</Title>
                        <ValueData>{user.tipo_proveedor_id}</ValueData>
                    </div>
                </Grid>
                <Title>Responsable frente al IVA</Title>
                <ValueData>{user.responsabilidad_iva}</ValueData>
                <Title>Datos de contacto</Title>
                <ValueDataIcon>
                    <LocationOnIcon/>
                    {`${user.direction}, ${user.location}, ${user.department}, ${user.province}, ${user.country} (CP ${user.cp})`}
                </ValueDataIcon>
                <ValueDataIcon><AlternateEmailIcon/>{user.email}</ValueDataIcon>
                <Grid>
                    <ValueDataIcon><SmartphoneIcon/>{user.mobile}</ValueDataIcon>
                    <ValueDataIcon><CallIcon/>{user.phone}</ValueDataIcon>
                </Grid>
        </DescriptionUser>
    </ProfileuserWrapper>
}

export default Profileuser

const ProfileuserWrapper = styled.div`
    width: calc(100% - 5rem);
    display: grid;
    min-height: 100px;
    padding: 0 2rem;
    grid-template-columns: 130px 3fr;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;  
        padding: 0;
        width: calc(100% - 2rem);
    }
`
const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
`

const DescriptionUser = styled.div`
    width: 100%;
    display: grid;
    padding: 0 3rem;
    @media (max-width: 800px) {
        width: auto;
        padding: 1rem 0;
    }
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 150px);
`

const ProfileImg = styled.img`
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    width: 130px;
    height: 130px;
`

const Title = styled.div`
    text-transform: uppercase;
    font-size: .7rem;
    color: #6f6f6f;
    font-weight: 600;
`

const ValueData = styled.div`
    color: #393939;
    font-size: .8rem;
    font-weight: 600;
    margin-bottom: .5rem;
`

const ValueDataIcon = styled.div`
    color: #393939;
    font-size: .8rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    svg{
        transform: scale(.6);
    }
`