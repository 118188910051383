import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';

function SimpleDialog(props) {
    const { onClose, open, title = false, component } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <DialogFake maxWidth='md' onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            {!!title ? <DialogTitle id="simple-dialog-title">{title}</DialogTitle> : null}
            <DialogBody>
                {component}
            </DialogBody>
        </DialogFake>
    );
}

DialogGeneric.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default function DialogGeneric(props) {

    const { title = false, component, button = false, show = null, close = null, handleShow, handleForceClose, disabled = false  } = props

    const [open, setOpen] = React.useState(!button ? true : false);

    const handleClickOpen = () => {
        if (!disabled) {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
        if (show !== null) {
            handleShow()
        }       
    };

    const handleCloseForce = () => {
        setOpen(false);
        if (close !== null) {
            handleForceClose() 
        }
    }

    useEffect(() => {
        if (close === true) {
            handleCloseForce()
        }
    }, [close])

    useEffect(() => {
        if (show === true) {
            handleClickOpen()
        }
    }, [show])

    return (
        <DialogWrapper>
            {!!button && (
                <ButtonFake onClick={handleClickOpen}>
                    {button}
                </ButtonFake>
            )}

            <SimpleDialog title={title} component={component} open={open} onClose={show !== null ? handleClose : handleCloseForce } />
        </DialogWrapper>
    );
}

const DialogWrapper = styled.div`
    position: relative;
`

const DialogBody = styled.div`
    width: calc(100% - 2rem);
    padding: 1rem;
`

const ButtonFake = styled.div`
    display: flex;
    justify-content: center;
`

const DialogFake = styled(Dialog)`
    div {
        overflow-y: visible;
    }
`