import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TableGeneric from "../../components/common/TableGeneric";
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CardGeneric from "../../components/common/CardGeneric";
import { getOrders } from "../../redux/api/orders";
// import { useSelector } from "react-redux";
import PageLoading from "../../components/common/PageLoading";
import ErrorPage from "../../components/common/ErrorPage";
import { ButtonBlue, ButtonGreen, ButtonRed } from "../../components/common/ButtonsGenerics";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CheckIcon from '@material-ui/icons/Check';
import TakeOrders from "../../components/templates/orders/TakeOrders";
import SearchHistoryOrders from "../../components/templates/orders/SearchHistoryOrders";
import DialogGeneric from "../../components/common/DialogGeneric";
import InfoOrder from "../../components/ui/organisms/orders/InfoOrder";
import DeleteOrder from "../../components/ui/organisms/orders/DeleteOrder";
import { useSnackBar } from "../../hooks/useSnackBar";
import { SnackBarGeneric } from "../../components/common/SnackBarGeneric";
// import { useSelector } from "react-redux";
import ConfirmOrder from "../../components/ui/organisms/orders/ConfirmOrder";
import SearchGeneric from "../../components/common/SearchGeneric";
import { Button } from "@material-ui/core";
import { format } from "../../helpers/FormatDate.helper";
import { useSelector } from "react-redux";

const Orders = () => {

    const [orders, setOrders] = useState(null)
    const [ordersPage, setOrdersPage] = useState(null)
    const [historyOrders, setHistoryOrders] = useState(null)
    const [closeDialog, setCloseDialog] = useState(false)
    const [filtered, setFiltered] = useState(null)
    const [filteredHistory, setFilteredHistory] = useState(null)

    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        if (orders === null) {
            getOrdersRequest()
        }
    }, [orders])

    const getOrdersRequest = async () => {
        setOrders(null)
        const request = await getOrders()
        if (request[0]?.statusCode === 404) {
            setOrders([])
            return
        }
        if (request.error) {
            setOrders(false)
            return
        }
        setOrders(request)
    }

    const handleNextPage = () => {
        setOrdersPage(1)
    }

    const handleBackPage = () => {
        setOrdersPage(null)
    }

    const handleHistoryOrders = (orders) => {
        setHistoryOrders(orders)
    }

    const handleRefresh = () => {
        setOrders(null)
        setOrdersPage(null)
    }

    const handleForceClose = () => {
        setCloseDialog(!closeDialog)
    }

    const handleCloseConfirm = id => {
        const newOrders = orders.filter(order => order.id !== id)
        setOrders(newOrders)
        handleForceClose()
    }

    const handleCloseDelete = (id) => {
        const orders = historyOrders?.filter(order => order.id !== id)
        setHistoryOrders(orders)
        handleForceClose()
    }

    const handleValues = (filtered) => {
        setFiltered(filtered)
    }

    const handleValuesHistory = (filtered) => {
        setFilteredHistory(filtered)
    }

    if (orders === null) {
        return <PageLoading h="50vh" w="100%" />
    }

    if (orders === false) {
        return <CardGeneric title={"Órdenes disponibles"} icon={<ReceiptIcon />}>
            <ErrorPage button={<ButtonBlue onClick={getOrdersRequest}>Reintentar</ButtonBlue>} message="¡No se pudo obtener las ordenes!" />
        </CardGeneric>
    }

    if (ordersPage) {
        return <TakeOrders handleRefresh={handleRefresh} handleBackPage={handleBackPage}/>
    }

    const columns = [
        { id: 'profesional', label: 'Profesional'},
        { id: 'fecha', label: 'Fecha' },
        { id: 'id', label: 'N° de orden' },
        { id: 'afiliado', label: 'Afiliado' },
        { id: 'tipo_documento', label: 'Tipo Doc' },
        { id: 'numero_documento', label: 'documento' },
        { id: 'acciones', label: 'Acciones' },
    ]

    const tableOrders = () => {
        let result = []
        if (orders) {
            result = orders.map(order => {
                return {
                    ...order,
                    prestador: order.prestador_id?.name,
                    fecha: format(order?.fecha),
                    acciones: <Actions>
                        <DialogGeneric handleForceClose={handleForceClose} close={closeDialog} button={<ButtonBlue><FormatListBulletedIcon /></ButtonBlue>} component={<InfoOrder handleClose={handleForceClose} id={order.id}/>}/>
                        <DialogGeneric handleForceClose={handleForceClose} close={closeDialog} button={<ButtonGreen><CheckIcon /></ButtonGreen>} component={<ConfirmOrder id={order.id} handleCloseConfirm={handleCloseConfirm} handleClose={handleForceClose}/>}/>
                        <DialogGeneric handleForceClose={handleForceClose} close={closeDialog} button={<ButtonRed><DeleteForeverIcon /></ButtonRed>} component={<DeleteOrder id={order.id} handleClose={handleCloseDelete}/>}/>
                    </Actions>,
                }
            })
        }
        return result.reverse();
    }

    const tableHistoryOrders = () => {
        let result = []
        if (historyOrders) {
            result = historyOrders.map(order => {
                return {
                    ...order,
                    prestador: order.prestador_id?.name,
                    acciones: <Actions>
                        <DialogGeneric button={<ButtonBlue><FormatListBulletedIcon /></ButtonBlue>} component={<InfoOrder id={order.id}/>}/>
                        <DialogGeneric disabled={order.estado_orden === 'Disponible' ? false : true} handleForceClose={handleForceClose} close={closeDialog} button={<ButtonRed disabled={order.estado_orden === 'Disponible' ? false : true}><DeleteForeverIcon /></ButtonRed>} component={<DeleteOrder id={order.id} handleClose={handleCloseDelete}/>}/>
                    </Actions>,
                }
            })
        }
        return result
    }


    return <OrdersWrapper>
        {!!user.permitir_crear_ordenes_desde_dol ? (
            <CardGeneric title={"Órdenes disponibles"} icon={<ReceiptIcon />} handleClick={handleNextPage} button={Button} buttonName={"Emitir orden"}>
                <SearchGeneric title="Buscar ordenes" handleValues={handleValues} filterNames={['id', 'numero_documento', 'afiliado']} values={tableOrders()} />
                <TableGeneric columns={columns} rows={filtered || tableOrders()} pageSize={5} />
            </CardGeneric>
        ) : (
            <CardGeneric title={"Órdenes disponibles"} icon={<ReceiptIcon />}>
                <SearchGeneric title="Buscar ordenes" handleValues={handleValues} filterNames={['id', 'numero_documento', 'afiliado']} values={tableOrders()} />
                <TableGeneric columns={columns} rows={filtered || tableOrders()} pageSize={5} />
            </CardGeneric>
        )
        }
        <CardGeneric title={"Historial de órdenes"} icon={<SearchRoundedIcon />} >
            <SearchHistoryOrders handleHistoryOrders={handleHistoryOrders}/>
        </CardGeneric>
        {historyOrders && (
            <CardGeneric title={""} icon={<SearchRoundedIcon />} >
                <SearchGeneric title="Buscar ordenes" handleValues={handleValuesHistory} filterNames={['id', 'numero_documento', 'afiliado']} values={tableHistoryOrders()} />
                <TableGeneric columns={columns} rows={filteredHistory || tableHistoryOrders()} pageSize={5} />
            </CardGeneric>
        )}
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </OrdersWrapper>
}

export default Orders;

const OrdersWrapper = styled.div`
    width: 100%;
`

const Actions = styled.div`
    display: flex;
    justify-content: flex-start;
`