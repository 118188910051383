import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useForm } from '../../../../hooks/useForm';
import { useSnackBar } from '../../../../hooks/useSnackBar';
import { createOrder } from '../../../../redux/api/orders';
import { getProfesional, getPlansService } from '../../../../redux/api/utils';
import { ButtonGreen } from '../../../common/ButtonsGenerics';
import InputFile from '../../../common/InputFile';
import PageLoading from '../../../common/PageLoading';
import InfoUser from '../../../common/user/InfoUser';
import {SnackBarGeneric} from "../../../common/SnackBarGeneric";
import { createPharmacyOrder } from '../../../../redux/api/phamarcy';

const initialForm = {
    affiliate: null,
    plan: null,
    medical: null,
    message: null
}


const PharmacyForm = () => {

    const [affiliate, setAffiliate] = useState(null);
    const [medical, setMedical] = useState(null)
    const [plans, setPlans] = useState(null)

    const [loading, setloading] = useState(false)

    // Form
    const [form, handleFormInput, status] = useForm(initialForm)
    const { user, family } = useSelector(state => state.auth)

    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    const affiliates = [user, ...family]

    useEffect(() => {
        getPlans()
        getMedical()
    }, [])

    // useEffect(() => {
    //     if (currentProvider !== null) getProfesionalComp(currentProvider)
    // }, [currentProvider])

    const handleChangeAffiliate = (event) => {
        setAffiliate(affiliates.filter(aff => aff.afiliado_id === event.target.value)[0]);
    };


    const getPlans = async () => {
        const request = await getPlansService(user.afiliado_id)
        if (!request.error) {
            setPlans(request)
        }
    }

    const getMedical = async () => {
        const request = await getProfesional()
        if (!request.error) {
            setMedical(request)
        }
    }

    const handleSubmit = async () => {
        setloading(true)
        const data = {
            afiliado_id: form.affiliate,
            plan_id: form.plan,
            medico_solicitante: form.medical,
            comentario: form.message
          }

        const request = await createPharmacyOrder(data)
        if (request.error) {
            openSnackBar(
                "error",
                "Error al crear la solicitud de medicamento"
            );
        }
        else{
            openSnackBar(
                "success",
                "Se creó correctamente"
            );
        }
        setloading(false)
    }

    return <PharmacyFormWrapper>
        <Left>
            <FormControlFake>
                <InputLabel id="1">Seleccione un afiliado</InputLabel>
                <SelectFake
                    labelId="1"
                    value={form.affiliate}
                    onChange={e => {
                        handleChangeAffiliate(e)
                        handleFormInput(e)
                    }}
                    name="affiliate"
                >
                    {affiliates.map(member => <MenuItem value={member.afiliado_id}>{`${member.nombre} ${member.apellido}`}</MenuItem>)}
                </SelectFake>
            </FormControlFake>
            <Grid>
                {!!plans && <FormControlFake>
                    <InputLabel id="1">Plan</InputLabel>
                    <SelectFake
                        disabled={!plans}
                        labelId="1"
                        name="plan"
                        value={form.plan}
                        onChange={handleFormInput}
                    >
                        {plans.map(plan => <MenuItem value={plan.id}>{`${plan.name}`}</MenuItem>)}
                    </SelectFake>
                </FormControlFake>}
                
            </Grid>
            {medical && <FormControlFake>
                <InputLabel id="1">Medico solicitante</InputLabel>
                <SelectFake
                    disabled={!medical}
                    labelId="1"
                    name="medical"
                    value={form.medical}
                    onChange={handleFormInput}
                >
                    {medical.map(med => <MenuItem value={med.id}>{`${med.nombre} ${med.apellido}`}</MenuItem>)}
                </SelectFake>
            </FormControlFake>}
            <TextFieldFake
                id="date"
                label="Prestacion"
                type="text"
                multiline
                rows={4}
                name="message"
                value={form.message}
                onChange={handleFormInput}
            />
        </Left>
        <Right>
            {affiliate !== null && (
                <InfoUser affiliate={affiliate} />
            )}
            <InputFile />
        </Right>
        <Footer>
            {!loading ? 
            (<ButtonGreen onClick={handleSubmit} disabled={!status}>Solicitar pedido farmacia</ButtonGreen>):
            (<PageLoading h="auto" w="100%"/>)}
        </Footer>
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </PharmacyFormWrapper>
}

export default PharmacyForm;

const PharmacyFormWrapper = styled.div`
    width: auto;
    display: grid;
    display: grid;
    grid-template-areas: "left right"
                         "footer footer";
    gap: .5rem;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-template-areas: "left" 
                            "right"
                            "footer";
    }
`
const Grid = styled.div`
    width: auto;
    display: grid;
    gap: 1rem;
    margin: 1rem 0;
`

const Left = styled.div`
    grid-area: left;
    width: 100%;
`

const Right = styled.div`
    grid-area: right;
    width: 100%;
`

const FormControlFake = styled(FormControl)`
    width: 100%;
`

const SelectFake = styled(Select)`
    width: 100%;
`

const TextFieldFake = styled(TextField)`
    width: 100%;
    margin: 1rem 0;
`

const Footer = styled.div`
    grid-area: footer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`