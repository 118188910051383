import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from '../../../../helpers/currency.format.helper';
import { toTitleCase } from '../../../../helpers/TitleCase.helper';
import { BadgeUI } from '../../../common/Badge';
import { Text } from '../../../common/Text';


const InfoDebt = props => {

    const { user, info } = props 

    return <InfoDebtWrapper>
        <User>
            <Photo src={user.img}/>
            <Data>
                <Text color="#555555" fontSize="1.2rem" fontWeight="600">{toTitleCase(`${user.nombre} ${user.apellido}`)}</Text>
                <Text color="#555555" fontSize="1.2rem" fontWeight="600">CTA. CTE. N° {info?.id} | {info?.mes}/{info?.anio}</Text>
                <BadgeUI bg="#58942f" color="white">{info?.estado.toUpperCase()}</BadgeUI>
            </Data>
        </User>
        <CardsContainer>
            <Tarjet color="#58942f" bg="#b5e6a1cc">
                <Text fontWeight="600">TOTAL ACREDITADO</Text>
                <Text fontSize="1.3rem" fontWeight="700">${formatCurrency(info?.importe_abonado.toFixed(2))}</Text>
            </Tarjet>
            <Tarjet color="#94402f" bg="#e8b5a2cc">
                <Text fontWeight="600">TOTAL DEBITADO</Text>
                <Text fontSize="1.3rem" fontWeight="700">${formatCurrency(info?.importe.toFixed(2))}</Text>
            </Tarjet>
            <Tarjet color="#4e617a" bg="#a7dcebcc">
                <Text fontWeight="600">SALDO</Text>
                <Text fontSize="1.3rem" fontWeight="700">${formatCurrency(info?.saldo.toFixed(2))}</Text>
            </Tarjet>
        </CardsContainer>
    </InfoDebtWrapper>
}

export default InfoDebt;

const InfoDebtWrapper = styled.div`
    width: calc(100% - 2rem);
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }
`

const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
`

const Tarjet = styled.div`
    background-color: ${p => p.bg};
    color: ${p => p.color};
    height: 100px;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    @media (max-width: 800px) {
        font-size: .7rem;
        height: auto;
    }
`

const User = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`

const Photo = styled.img`
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    margin-right: 1rem;
`

const Data = styled.div`
    width: auto;
    position: relative;
`