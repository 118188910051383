import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CardGeneric from "../../components/common/CardGeneric";
import ProfileProvider from "../../components/ui/organisms/provider/Profile";
import PersonIcon from '@material-ui/icons/Person';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { useSelector } from "react-redux";
import DialogGeneric from "../../components/common/DialogGeneric";
import FormUpdateProvider from "../../components/common/user/FormUpdate";
import TableGeneric from "../../components/common/TableGeneric";
import { getSpecialitiesByProvider } from "../../redux/api/specialities";
import PageLoading from "../../components/common/PageLoading";
import SearchGeneric from "../../components/common/SearchGeneric";
// import FormUpdateProvider from "../../components/common/user/FormUpdateProvider";

const Provider = () => {

    const [show, setShow] = useState(false)
    const [closeDialog, setCloseDialog] = useState(false)
    const [specialities, setSpecialities] = useState(null)
    const [filtered, setFiltered] = useState(null)

    const columns = [
        { id: 'name', label: 'Nombre' },
        { id: '', label: '' },
        { id: '', label: '' },
    ]

    const { user, rol } = useSelector(state => state.auth)
    console.log(rol);

    useEffect(() => {
        !specialities && getSpecialities()

    }, [specialities])

    const getSpecialities = async () => {
        const request = await getSpecialitiesByProvider()
        if (request.error) {
            alert("Error")
        }
        else {
            tableRender(request)
        }
    }

    const tableRender = array => {
        const rows = array.map(item => ({
            ...item,
            activeCheck: <FormControlLabel disabled control={<Checkbox defaultChecked={!!item.active} />} />,
            damsuCheck: <FormControlLabel disabled control={<Checkbox defaultChecked={!!item.damsu} />} />
        }))
        setSpecialities(rows)
    }

    const handleShow = () => {
        setShow(!show)
    }

    const handleForceClose = () => {
        setCloseDialog(!closeDialog)
        setShow(false)
    }

    const handleValues = (filtered) => {
        setFiltered(filtered)
    }


    return <ProviderWrapper>
        <CardGeneric title={"Prestador"} icon={<PersonIcon />} handleClick={handleShow} button={rol !== 'subprestador' ? Button : false} buttonName={rol !== 'subprestador' ? "Editar" : false}>
            <ProfileProvider />
            <DialogGeneric close={closeDialog} handleForceClose={handleForceClose} handleShow={handleForceClose} show={show} button={true} component={<FormUpdateProvider headline={true} handleClose={handleForceClose} id={user.prestador_id} />} />
        </CardGeneric>
        {!!specialities ? (
            <CardGeneric title={"Lista de especialidades convenidas"} icon={<FormatListBulletedIcon />}>
                <SearchGeneric title="Buscar especialidades" handleValues={handleValues} filterNames={['name']} values={specialities} />
                <TableGeneric columns={columns} rows={filtered || specialities} pageSize={5} />
            </CardGeneric>
        ) : (
            <PageLoading h="50px" />
        )}
    </ProviderWrapper>
}

export default Provider;

const ProviderWrapper = styled.div`
    width: 100%;
`